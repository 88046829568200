function createPerformanceTrack({ url } = {}) {
  if (typeof window === 'undefined' || typeof PerformanceObserver === 'undefined') return () => { }
  function perf_observer(list, observer) {
    const resource = list.getEntries().find(entry => entry.name.includes(url))
    if (!resource) return
    const {
      duration,
      decodedBodySize,
      responseStart,
      responseEnd,
      transferSize,
      responseStatus,
    } = resource
    observer.disconnect()
    if (responseStatus !== 200) return // 如果请求失败，不上报 

    // 将数据b改为kb数据 并返回整数。 例如30000b 返回30kb
    function formatSize(size) {
      return parseInt(+(size / 1024).toFixed(2))
    }
    const data = {
      duration: duration && parseInt(duration), // 总耗时
      contentDownload: parseInt(responseEnd - responseStart), // 下载耗时
      decodedBodySize: decodedBodySize && formatSize(decodedBodySize), // 原始大小
      transferSize: transferSize && formatSize(transferSize) // 传输大小(包括请求头和响应头)
    }
    reportPerformanceData(data)
  }
  const observer = new PerformanceObserver(perf_observer)
  observer.observe({ entryTypes: ['resource'] }) // 采集资源性能数据
  return () => { requestAnimationFrame(() => observer?.disconnect?.()) }
}

function reportPerformanceData(param) {
  requestIdleCallback(() => {
    const data = {
      data: Object.keys(param).map(key => {
        return {
          key_path: `bff_search_filter_${key}`,
          values: {
            num: param[key]
          }
        }
      }),
    }
    if (gbCommonInfo?.isDebug) {
      console.log('performance data report:', data)
    }
    window.TPM?.run({
      marketing: 'ClientAnalytics',
      method: '_defineTrack',
      params: {
        data,
        options: {
          random: 1,
          immediate: true,
        },
      }
    })
  })
}

export { createPerformanceTrack }
