export const isGeneralProductList = (route) => {
  return route.name === 'product-list-v2'
}

// 转query的词 (从 图文，锦囊，分布式筛选 来)
export const getQueryWords = (route) => {
  // 图文搜索场景，filterWords 为分类名，需要入参到 bff 的 get_products_by_keywords 接口的 keywords 字段
  if (route?.query?.extra_keywords - 1 === 0) {
    return [route?.query?.filterWords]
  }
  return route?.query?.filterAttrIds?.split('`')?.map(str => str.split(',')[0]) || []
}

export const isSearch = (type) => type === 'search' // 搜索(全站，店铺，专题)
export const isAllSearch = (type, query) => isSearch(type) && !isStoreSearch(type, query) && !isBrandSearch(type, query) && !isTopTrendResultPage(type, query) // 全站搜索
export const isStoreSearch = (type, query) => isSearch(type) && query.search_type === 'store' // 店铺搜索
export const isBrandSearch = (type, query) => isSearch(type) && query.search_type === 'brand' // 专题搜索
export const isTopTrendResultPage = (type, query) => isSearch(type) && query.search_type === 'pageTrend' // v.趋势搜索结果页屏蔽该功能

export const isPicksRoute = (route) => {
  return route.name === 'shein-picks'
}

export const cleanNullValueObjectFirstLevel = (params) => {
  if (typeof params !== 'object' || params === null) {
    return params
  }

  const cleanObj = {}

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key]

      // 仅在值不为undefined, null或空字符串时赋值
      if (value !== undefined && value !== null && value !== '') {
        cleanObj[key] = value
      }
    }
  }

  return cleanObj
}

/**
* 获取入口类型
*/
export const getEntranceType = (catInfo) => {
  let entranceType = catInfo?.entranceType || ''

  // 从首页的 sbc 区域点击进入列表时，url 会携带 entranceType=sbc
  if (catInfo?.entranceType === 'sbc') {
    entranceType = 'sbc'
  }

  // 从 footer 的 Category 区域点击进入列表时，url 会携带 fromPageType=category
  if (catInfo?.fromPageType === 'category') {
    entranceType = 'category'
  }

  return entranceType
}

/**
 * 商品接口是否传adp
 * 图文筛选 且 该图文有goods_id，则传该goodsId
 * 存在筛选条件，则传空
 * 无筛选条件，且则传默认query上adp
 * @param {*} query
 */
export const getGoodsAdp = query => {
  if (query.pic_nav_adp) return query.pic_nav_adp
  const hasFilter = [
    'child_cat_id',
    'attr_ids',
    'brand_ids',
    'tag_ids',
    'min_price',
    'max_price',
    'pic_nav_id',
    'tsp_ids'
  ].some(key => query[key] || query[key] === 0)

  return hasFilter ? '' : (query.adp || '')
}
