
import { defaultRequestInfo, setCurrentToRoute, PointerOverGoodsRequest, generateRouterParams, generateRealSelectBffFilterInfo, preloadPicTopNav } from './utils'
import { PRODUCT_LIST_ROUTE_NAME, ROUTE_REGEX } from './constant.js'
import generateParams from './generateParams'
import { getSearchGenerateBffParams, getRealGenerateBffParams, getSelectGenerateBffParams, getPickedGenerateBffParams } from './bffParamsHandle'
import { markPoint } from 'public/src/services/mark/index.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import { abortionInstance, apiCache } from './api/nodeApi.js'
import { 
  cccFeedbackAbortionInstance,
  cccFeedbackApiCache,
  goodsAbortionInstance, 
  goodsApiCache, 
  searchFilterAbortionInstance, 
  searchFilterApiCache, 
  selectionAndEntityFilterAbortionInstance,
  selectionAndEntityFilterApiCache,
  selectionAndEntityPicNavAbortionInstance, 
  selectionAndEntityPicNavApiCache, 
  selectionAndEntityCloudTagAbortionInstance, 
  selectionAndEntityCloudTagApiCache,
  categoryDayTagApiCache,
  cccCommonComponentApiCache,
  pickedFilterApiCache,
  pickedCloudTagApiCache,
  pickedFilterAbortionInstance,
  pickedCloudTagAbortionInstance
} from './api/bffApi.js'
import { promiseAsyncAuto } from './asyncAuto.js'
import { userTracekPreCheckEnd } from 'public/src/pages/common/userActionTracker/index.js'

function clearApiCache() {
  apiCache.clear()
}

/**
 * 获取商品列表数据的接口调用
 * @customRequestQueue 自定义队列, 可以在指定的时机调用，例如在获取筛选数据之后就调用
 * */ 
const requestProductListApi = async ({ requestInfo, newSrcTabPageId, toRoute, useNodeApi, customRequestQueue = {} }) => {
  if (typeof window === 'undefined') return

  // const requestStrategy = requestInfo.query.isClickRefresh ? '点刷类型' : '分页类型'

  // console.log(`%c 当前请求类型为: ${requestStrategy} 其中 page: ${requestInfo.query.page} limit: ${requestInfo.query.limit} `, 'background: #222; color: #bada55')

  const nodeRequest = getNodeRequest(requestInfo, newSrcTabPageId)
  const bffRequestQueue = getBffRequestQueue(requestInfo, toRoute, newSrcTabPageId)
  const isBff = !useNodeApi && Object.keys(bffRequestQueue).length
  const isUserBehavior = toRoute?.path?.match(ROUTE_REGEX.pickedProducts)
  const isPicked = toRoute?.path?.match(ROUTE_REGEX.picked)
  try {
    if (isBff) {
      const data = await promiseAsyncAuto({
        nodeRes: nodeRequest({
          useBffProducts: 1,
          useBffFilters: 1, 
          withoutAbort: requestInfo.query?.withoutAbort,
        }),
        ...bffRequestQueue,
        bffFilterInfo: ['nodeRes', 'filterData', (result, cb) => {
          if (!result.filterData) return cb(null, {})
          // filter相关数据都在这里处理。
          preloadPicTopNav({ nodeRes: result.nodeRes, toRoute, bffFilterInfo: result.filterData, isBff: true })
          cb(null, result.filterData)
        }],
        ...customRequestQueue,
      })
      const bffGoodsResult = data.goods || {}
      const bffCccFeedbackResult = data.cccFeedbackData || {}
      const cccCommonComponentResult = data.cccCommonComponentApiData || {}
      const resultData = {
        ...(data.nodeRes || {}),
        bffFilterInfo: data.bffFilterInfo || {},
        bffProductsInfo: bffGoodsResult.code === '0' ?  { code: '0', ...bffGoodsResult.info } : {},
        bffCccFeedbackInfo: bffCccFeedbackResult.code === '0' ? bffCccFeedbackResult.info : {},
        isAdultRankSwitchBff: isSwitchBffApiVersion(['adult_rank_v1'])?.adult_rank_v1,
        cccCommonComponentResult,
        _filterRenderHandle: data._filterRenderHandle,
      }

      if (isUserBehavior) {
        // eslint-disable-next-line no-unused-vars
        let { products, ...res } = resultData.bffProductsInfo
        Object.assign(resultData, {
          ...res
        })
      }

      handleGoodsImgLessenIndex(resultData)
      return resultData
    } else {
      const result = await nodeRequest()
      return result
    }
  } catch (e) {
    console.error(e)
    // 用于点后刷判断错误类型
    if (isPicked) {
      return { error: e }
    }
    return {}
  }
}

const getNodeRequest = (requestInfo, newSrcTabPageId) => {
  const params = generateParams(requestInfo, newSrcTabPageId)

  return ({ useBffProducts, useBffFilters, withoutAbort } = {}) => apiCache.request({ ...params, useBffProducts, useBffFilters, withoutAbort })
}

/**
 * bff 请求队列
*/

// 图文导航
const requestPicNav = (requestInfo, picNavParams) => {
  const hidePicNav = requestInfo.query.is_hide_image_nav === '1'
  if (hidePicNav) {
    return new Promise(resolve => {
      resolve({})
    })
  }

  return selectionAndEntityPicNavApiCache.request(picNavParams)
}
const getBffRequestQueue = (requestInfo, toRoute, newSrcTabPageId) => {
  const SEARCH_V1 = 'search_v1'
  const PICKED_V1 = 'picked_v1'
  const SELECTION_V1 = 'selection_v1'
  const bffSwitchVersion = isSwitchBffApiVersion([SEARCH_V1, PICKED_V1, SELECTION_V1, 'adult_rank_v1'])
  const isSearchSwitchBff = bffSwitchVersion?.[SEARCH_V1]
  const isPickedSwitchBff = bffSwitchVersion?.[PICKED_V1]
  const isSelectSwitchBff = bffSwitchVersion?.[SELECTION_V1]
  const isAdultRankSwitchBff = bffSwitchVersion?.adult_rank_v1

  const isSearch = toRoute?.path?.match(ROUTE_REGEX.search)
  const isSelect = toRoute?.path?.match(ROUTE_REGEX.select)
  const isReal = toRoute?.path?.match(ROUTE_REGEX.real)
  const isPicked = toRoute?.path?.match(ROUTE_REGEX.picked)
  const isUserBehavior = toRoute?.path?.match(ROUTE_REGEX.pickedProducts)

  if (isSearch && isSearchSwitchBff) {
    const { goodsParams, filterParams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
    const reqObj = {
      goods: goodsApiCache.request({ 
        type: 'search', 
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }),
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const res = await searchFilterApiCache.request({ type: 'search', params: filterParams }) // 图文导航，分类/属性/tsp筛选，标签云
        cb(null, res)
      },
    }
    return reqObj
  } else if (isSelect && isSelectSwitchBff) {
    const { goodsParams, filterParams, picNavParams, cloudTagParams, categoryDayTagParams, cccCommonComponentParams } = getSelectGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({ 
        type: 'selection', 
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {}) 
        filterParams._requestType = 'selection'
        filterParams._type = 'selection'
        picNavParams._requestType = requestInfo.type  // 传入，里面判断删除
        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffPicNavResult: requestPicNav(requestInfo, picNavParams), // 图文导航
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
          bffDailyDatesResult: categoryDayTagApiCache.request(categoryDayTagParams) // 标签云中的date标签数据  没有show_daily不会请求
        })
        cb(null, generateRealSelectBffFilterInfo(result))
      },
      cccCommonComponentApiData: async (cb) => {
        if (!isAdultRankSwitchBff) {
          return cb(null, null)
        }
        if (requestInfo.type === 'nextpage') return cb(null, null)
        const result = await cccCommonComponentApiCache.request(cccCommonComponentParams)
        cb(null, result)
      },
    }
    return queueObj
  } else if (isReal && isSelectSwitchBff) {
    const { goodsParams, filterParams, picNavParams, cloudTagParams, cccCommonComponentParams } = getRealGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({ 
        type: 'entity', 
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        picNavParams._requestType = requestInfo.type   // 传入，里面判断删除
        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffPicNavResult: requestPicNav(requestInfo, picNavParams), // 图文导航
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result))
      },
      cccCommonComponentApiData: async (cb) => {
        if (!isAdultRankSwitchBff) {
          return cb(null, null)
        }
        if (requestInfo.type === 'nextpage') return cb(null, null)
        const result = await cccCommonComponentApiCache.request(cccCommonComponentParams)
        cb(null, result)
      },
    }
    return queueObj
  } else if (isPicked && isPickedSwitchBff) {
    const { goodsParams, filterParams, cloudTagParams } = getPickedGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'picked',
        params: goodsParams,
        addUserBehaviorParams:
          requestInfo.query?.channelIndex === '0' &&
          ![12, 13].includes(+requestInfo.query?.carrierSubType) &&
          requestInfo.query?.addUserBehaviorParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const result = await promiseAsyncAuto({
          bffCateAttrResult: pickedFilterApiCache.request({ params: filterParams }), // 分类/属性/tsp筛选
          bffCloudTagResult: pickedCloudTagApiCache.request({ params: cloudTagParams }), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result))
      }
    }
    return queueObj
  } else if (isUserBehavior) {
    const params1 = generateParams(requestInfo, newSrcTabPageId)
    const params = {
      page: params1?.page || 1,
      size: +params1?.size || 20,
      carrierSubType: params1?.carrierSubType || '',
      contentCarrierId: params1?.contentCarrierId || '',
      entranceGoodsId: params1?.entranceGoodsId || '',
    }
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'userBehavior',
        params: params
      }),
    }
    return queueObj
  }
  return {}
}


// 优化策略商品图先上屏
function handleGoodsImgLessenIndex(data) {
  const isPicTopNav = data?.picTopNav?.length || data.bffFilterInfo?.navs?.length
  if (data?.listAbtResult?.PerformanceLogNodeTestFetch?.p?.PerformanceLogNodeTestFetch !== 'YES' || !isPicTopNav) {
    return 
  }
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (isIos) return
  // 开启商品图片上屏优化
  // 判断图文，标签云，图文导航是否有数据，有数据则开启图片上屏优化
  data.imgItemPrefromanceIndex = 4
}

/**
 * 检测是否数据已经缓存
*/
const getProductListApiCache = (requestInfo, newSrcTabPageId, toRoute) => {
  const isSearchSwitchBff = isSwitchBffApiVersion(['search_v1'])?.search_v1
  const isSearch = toRoute?.path?.match(ROUTE_REGEX.search)
  if (isSearch && isSearchSwitchBff) {
    const { goodsParams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(goodsParams)
  } else {
    const params = generateParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(params)
  }
}

let prehtmlFlag = false // 单页水合时，不发起预请求
function changePreHtmlUpdate(value) {
  prehtmlFlag = value
}

/**
 * 路由的预请求
 * */
function handleProductListPreRequestBeforeEachRoute(to, from) {
  console.log('路由的预请求', from)
  if (prehtmlFlag) return // 单页水合时，不发起预请求
  const isProductListRoute = PRODUCT_LIST_ROUTE_NAME.includes(to.name)
  if (!isProductListRoute) return

  if (from.name === 'preload-request') {
    // 点击pointer触发的预请求， 由于快于beforeEach，所以在这里触发进行校验一次
    userTracekPreCheckEnd({ featureKey: 'click_refresh', to })
  }

  if (to?.name === 'product-list-v2') {
    markPoint({ eventName: 'RouterStartTrigger', measureTo: 'RouterEndTrigger' })
  }
  
  setCurrentToRoute(to)
  const isSpaRouteChange = (fromRoute) => Boolean(fromRoute.name)
  const isSameRouteChange = (to, from) => to.name === from.name
  if (isSpaRouteChange(from)) {
    if (!isSameRouteChange(to, from)) {
      requestProductListApi({ requestInfo: defaultRequestInfo(to.query), newSrcTabPageId: undefined, toRoute: to })
    }
  }
}

// to, from
function handleProductListPreRequestAfterEachRoute(to) {
  if (!prehtmlFlag && to?.name === 'product-list-v2') {
    markPoint({ eventName: 'RouterEndTrigger', measureTo: 'PageWaitInit' })
  }
}

if (typeof window !== 'undefined') {
  window.apiCacheProducts = apiCache
}

const abortRequest = (abortReason) => {
  cccFeedbackAbortionInstance?.abort?.(abortReason)
  abortionInstance?.abort?.(abortReason)
  goodsAbortionInstance?.abort?.(abortReason)
  searchFilterAbortionInstance?.abort?.(abortReason)
  selectionAndEntityFilterAbortionInstance?.abort?.(abortReason)
  selectionAndEntityPicNavAbortionInstance?.abort?.(abortReason)
  selectionAndEntityCloudTagAbortionInstance?.abort?.(abortReason)
  pickedFilterAbortionInstance?.abort?.(abortReason)
  pickedCloudTagAbortionInstance?.abort?.(abortReason)
}

/**
 * 触摸提前预请求方法。让请求提前触发
 * */
const pointerOverGoodsRequest = new PointerOverGoodsRequest({
  fetch: (path) => {
    const toRouteParams = generateRouterParams(path)
    if (!toRouteParams) return
    handleProductListPreRequestBeforeEachRoute(toRouteParams, { name: 'preload-request' })
  },
  cancelToken: () => abortRequest(),
})

export {
  abortionInstance,
  abortRequest,
  requestProductListApi,
  setCurrentToRoute,
  handleProductListPreRequestBeforeEachRoute,
  handleProductListPreRequestAfterEachRoute,
  pointerOverGoodsRequest,
  changePreHtmlUpdate,
  getProductListApiCache,
  clearApiCache,
}
