const PRODUCT_LIST_API_URL_MAP = {
  'product-list-v2': '/api/productList/info/get',
  'shein-picks': '/api/productList/picksInfo/get',
  'picked-info-list': '/api/recProductList/recInfo/get',
  'feedback-rec-list': '/api/recProductList/recInfo/get',
  'discount-channel-list': '/api/discountChannelList/disInfo/get',
  'picked-products': '/api/discountChannelList/disInfo/get',
}

// 属于泛列表的路由名字: 一般列表, 信息流列表 (包括大卡片), 实时反馈列表
const PRODUCT_LIST_ROUTE_NAME = [
  'product-list-v2',
  'picked-info-list',
  'feedback-rec-list',
  'discount-channel-list',
  'shein-picks',
  'picked-products'
]

const NOT_CACHE_KEYS = ['scici', 'src_module', 'src_identifier', 'src_tab_page_id', 'new_src_tab_page_id', 'src_identifier_pre_search', '_type', '_show_daily', '_requestType']

const PRODUCT_LIST_REQUEST_URL_MAP = {
  SEARCH: {
    goods: '/product/get_products_by_keywords', // 商品数据
    filters: '/search/get_aggregation_filters', // 分类，属性，tsp筛选，图文导航，标签云
  },
  REAL: {
    goods: '/category/real_category_goods_list', // 商品数据
    filters: '/category/real_category_attr_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/real_category_tags', // 标签云
  },
  SELEST: {
    goods: '/category/get_select_product_list', // 商品数据
    filters: '/category/select_category_attr_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/select_category_tags', // 标签云

  },
  PICKED: {
    goods: '/product/recommend/information_flow_selected_products', // 商品数据
    filters: '/product/recommend/information_flow_selected_filter', // 分类，属性，tsp筛选
    cloudTags: '/product/recommend/information_flow_selected_tags', // 标签云
  },
  COMMON: {
    categoryDay: '/category/select_category_day',
    picNav: '/category/nav_tab_index', // 图文导航
    cccFeedback: '/ccc/communal/switch',
    buyboxEntrance: '/recommend/buybox/buybox_entrance',  // buybox入口
    rankListComponent: '/category/rank_list_component', // 榜单坑位请求
    cccCommonComponent: '/ccc/common_component', // 公共组件
  },
  USER_BEHAVIOR: {
    goods: '/category-api/recommend/get-user-behavior-products', // 商品数据
  }
}

const ROUTE_REGEX = {
  search: /pdsearch\/([^\/]+)\/?$/,
  real: /-c-(\d+)\.html$/,
  select: /((\w+)\/)?[^/]+-sc-([A-Za-z0-9]+)\.html$/,
  picked: /picked-info-products\.html$/,
  pickedProducts: /picked-products\.html$/,
}

export {
  PRODUCT_LIST_ROUTE_NAME,
  PRODUCT_LIST_API_URL_MAP,
  NOT_CACHE_KEYS,
  PRODUCT_LIST_REQUEST_URL_MAP,
  ROUTE_REGEX
}
