/**
 * 通过入参已经曝光商品数据和点刷获取的新数据
 * 返回去除曝光后的商品数据
 * @param {*} newProducts 点刷返回的新商品数组
 * @param {*} exposedProducts 已经曝光的商品数组
 * @returns 
 */
function removeExposedProducts(newProducts = [], exposedProducts = []) {
  const exposedProductIds = new Set(exposedProducts.map(item => String(item.goods_id)).filter(Boolean))
  const filteredNewProducts = newProducts.filter(
    newProduct => !exposedProductIds.has(String(newProduct.goods_id))
  )

  return filteredNewProducts
}

/**
 * 获取点刷商品对应的 page / limit 信息
 * @param {*} exposedProducts 已经曝光的商品数组 
 * @param {*} page 点刷请求的 page
 * @param {*} limit 点刷请求的 limit
 * @returns 
 */
function computePageLimit(exposedProducts = [], limit = 20) {

  const page = Math.floor(exposedProducts.length / limit) + 1

  return {
    page,
    limit
  }
}

/**
 * 返回已曝光的商品以上的列表
 * @param {Object} Params 已经曝光的商品数组 
 * @param {*} Params.exposedProducts 通过商卡曝光事件收集的商品列表
 * @param {*} Params.goods 商品总列表
 * @returns 
 */
function getExposedGoods({ exposedProducts = [], goods = [] }) {
  // 过滤未曝光的商品
  const exposedProductIds = new Set(
    exposedProducts.map(item => item.goods_id).filter(Boolean)
  )
  let maxIndex = 0 // 最大已曝光商品的位置
  const total = goods.length
  for(let i = total; i >= 0; i--) {
    const item = goods[i]
    if (item?.goods_id && exposedProductIds.has(item.goods_id)) {
      maxIndex = i
      break
    }
  }
  return {
    maxIndex,
    exposedProducts: goods.slice(0, maxIndex + 1)
  }
}

export { removeExposedProducts, computePageLimit, getExposedGoods }
