import { getCommonParams, getVuexInfo } from './common.js'
import { cleanNullValueObjectFirstLevel } from './utils.js'
import { getCurrentToRoute } from '../utils'

// 商品接口入参
const getGoodsParams = requestInfo => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { searchKeywords } = getVuexInfo(toRoute)
  const {
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    mallCode,
  } = getCommonParams(query, searchKeywords)
  /**
   * 会员态怎么办？TODO:
   * reqSheinClub: atomicParams.reqSheinClub,
   * isPaid: atomicParams.isPaid,
   */

  let params = {
    adp: query.adp || query.topGoodsId || '',
    cat_id: catId,
    channel_id: channelId || query.channelId,
    content_id: query.contentCarrierId || '',
    filter,
    mall_code_list: mallCode,
    max_price: maxPrice,
    min_price: minPrice,
    position: +query.channelIndex + 1 || '',
    quickship,
    sort: query.sort || '',
    tag_ids: tagIds,
    exposedPosition: query.exposedPosition || 0,
    postClickRefresh: query?.channelIndex === '0' && query.postClickRefresh ? 1 : 0,
    isClickRefresh: query.isClickRefresh || 0,
    frontFilterGoods: query.frontFilterGoods,
    filter_goods_infos: query.filter_goods_infos,
    tspId: filterTagIds || query.tspIds,
    timestamp: query.timestamp,

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 筛选接口入参（属性，分类，tsp筛选）
const getFilterParams = requestInfo => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { searchKeywords } = getVuexInfo(toRoute)
  const {
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    mallCode,
  } = getCommonParams(query, searchKeywords)

  let params = {
    adp: query.adp || query.topGoodsId || '',
    cat_id: catId,
    channel_id: channelId || query.channelId,
    choosed_ids: query.choose_ids,
    content_id: query.contentCarrierId || '',
    filter,
    last_parent_cat_id: query.last_parent_cat_id,
    mall_code_list: mallCode,
    max_price: maxPrice,
    min_price: minPrice,
    position: +query.channelIndex + 1 || '',
    quickship,
    tag_ids: tagIds,
    tspId: filterTagIds || query.tsp_ids || query.tspIds,
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = requestInfo => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { searchKeywords } = getVuexInfo(toRoute)
  const {
    filter,
    catId,
    channelId,
    filterTagIds,
    maxPrice,
    minPrice,
    mallCode,
    tagIds,
  } = getCommonParams(query, searchKeywords)

  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    adp: query.adp || query.topGoodsId || '',
    cat_id: catId,
    channel_id: channelId || query.channelId,
    choosed_mall_code: mallCode,
    choosed_tag: choosed_tag,
    content_id: query.contentCarrierId || '',
    filter,
    max_price: maxPrice,
    min_price: minPrice,
    position: +query.channelIndex + 1 || '',
    tspId: filterTagIds || query.tspIds,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

/**
 *
 * @param {Object} requestInfo
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns
 */
export const getPickedGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    cloudTagParams: getCloudTagParams(requestInfo),
    filterParams: getFilterParams(requestInfo),
  }
}
