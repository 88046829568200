import { parseQueryString } from '@shein/common-function'
export const reg = /\/([a-z0-9]+-picks|romwe-[a-z]+|promo-discount)\.html/

// todo: transformPromodiscountUrl此方法服务端也在使用，后续抽离到common-function

/**
 * romwe/shein pick 路由转换
 * @description 根据 hrefTarget 将原始链接转换为特定的链接
 * @param {String} url 原始链接
 * @return {String} 结果链接
 */
export function transformPromodiscountUrl(url, locals) {
  let envData
  if (typeof window !== 'undefined') {
    envData = gbCommonInfo
  } else if (locals) {
    envData = locals
  } else {
    return url
  }
  const { IS_RW, PICKS_LINKS } = envData
  const query = url.split('?')[1]
  const match = url.match(reg)?.[0]
  if (!match) return url

  const newURL = PICKS_LINKS[parseQueryString(query || '').hrefTarget] || (IS_RW ? 'promo-discount' : 'shein-picks')
  url = url
    .replace(match, `/${newURL}.html`)
    .replace(/&?hrefTarget=\d+/, '')
  return url
}
