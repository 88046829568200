import dayjs from 'dayjs'
import { getRankListComponentParams, getViewedGoods, getCommonParams, getVuexInfo, getListMainParams, getPicNavParams  } from './common.js'
import { getCurrentToRoute } from '../utils'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'
import { getRefreshListStrategyParams } from './refreshListStrategyParams.js'

const getGoodsParams = (requestInfo, newSrcTabPageId) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { goods, catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'selectListPage')

  const {
    quickship,
    filter,
    catId,
    brandId,
    date,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    choosed_nav_pos: query.pic_nav_pos || '',
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    page_name: catInfo.pageName,
    src_tab_page_id: newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-',
    entranceType: query.entranceType || '',
    sort: query.sort || '',
    source: query.source || '',
    styleType: query.styleType || '',
    source_status: query.source_status || '',
    adp: getGoodsAdp(query),
    filter_goods_infos: getViewedGoods(toRoute, requestInfo, goods),
    from_store: Number(query?.pageType === 'store'),
    _type: 'selection',

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
    ...refreshListStrategyParams,
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选相关
const getFilterParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'selectListPage')
  const {
    quickship,
    filter,
    catId,
    brandId,
    date,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    filterMallCode,
    urlMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    // lastParentCatId,
    chooseIds,
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    // last_parent_cat_id: lastParentCatId,
    choosed_ids: chooseIds,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    cancel_filter_tag_ids: filterTagIds,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    page_name: catInfo.pageName,
    sort: query.sort || '',
    styleType: query.styleType || '',
    showAllCategory: query.showAllCategory || '',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    ...refreshListStrategyParams,
    _type: 'selection'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 标签云相关
const getCloudTagParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'selectListPage')
  const {
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
  } = getCommonParams(query, searchKeywords)


  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    ...mainParams,
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_tag,
    choosed_mall_code: filterMallCode,
    mall_code_list: urlMallCode,
    sort: query.sort || '',
    page_name: catInfo.pageName,
    page: query.page || 1,
    limit: query.limit || 10,
    kidRandom: query.kid_random || '', // 避免缓存
    styleType: query.styleType || '',
    from_store: Number(query?.pageType === 'store'),
    ...refreshListStrategyParams,
    _type: 'selection'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云中的date标签数据
const getCategoryDayTagParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const {
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    mallCode,
    filterMallCode,
    urlMallCode,
    tagIds
  } = getCommonParams(query, searchKeywords)

  const time = dayjs(Date.now())
  const today = time.format('YYYY-MM-DD')

  let params = {
    ...mainParams,
    today,
    quickship,
    filter,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    styleType: query.styleType || '',
    tag_ids: tagIds,

    _show_daily: query.show_daily || '',
    _type: 'selection'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

const __getRankListComponentParams__ = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  return {
    ...getRankListComponentParams(requestInfo),
    scene: 'select',
    select_id: mainParams.select_id,
  }
}

const __getCccCommonComponentParams__ = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)  
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  return {
    cccPageType: 'realSelectListPage',
    cateId: mainParams.select_id,
    cateType: 5,
  }
}

/**
 *
 * @param {Object} requestInfo
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns
 */
export const getSelectGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
    picNavParams: getPicNavParams(requestInfo, 'selection'),
    cloudTagParams: getCloudTagParams(requestInfo),
    categoryDayTagParams: getCategoryDayTagParams(requestInfo),
    rankListComponentParams: __getRankListComponentParams__(requestInfo),
    cccCommonComponentParams: __getCccCommonComponentParams__(requestInfo),
  }
}
