import schttp from 'public/src/services/schttp'
import { ApiCache } from '../../ApiCache/index'
import { handleGoodsImgLessen } from '../utils.js'
import { NOT_CACHE_KEYS, PRODUCT_LIST_REQUEST_URL_MAP } from '../constant.js'
import { preloadImg } from '../../../utlis/prefetch.js'
import { customMarkPoint } from 'public/src/services/mark/index.js'
import { createPerformanceTrack } from '../performanceTrack.js'

const { SEARCH, REAL, SELEST, PICKED, COMMON, USER_BEHAVIOR } = PRODUCT_LIST_REQUEST_URL_MAP


const deleteUselessParams = (params) => {
  if (params?._type) {
    delete params._type
  }

  if (params?._show_daily) {
    delete params._show_daily
  }

  if (params?._requestType) {
    delete params._requestType
  }

  return params
}

export let cccFeedbackAbortionInstance = null
export const cccFeedbackApiCache = new ApiCache({
  cacheTime: 10 * 60 * 1000, // 缓存时间 5 分钟
  request: async () => {
    if (cccFeedbackAbortionInstance) {
      cccFeedbackAbortionInstance.abort()
    }
    cccFeedbackAbortionInstance = new SchttpAbortCon()
  
    const res = await schttp({
      url: COMMON.cccFeedback,
      params: {},
      signal: cccFeedbackAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 商品列表
export let goodsAbortionInstance = null
export const goodsApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (goodsAbortionInstance) {
      goodsAbortionInstance.abort()
    }
    if (!options.withoutAbort) {
      goodsAbortionInstance = new SchttpAbortCon()
    } else {
      goodsAbortionInstance = null
    }
    
    const isSearch = options.type === 'search'
    const isPicked = options.type === 'picked'

    let url = ''
    if (isSearch) {
      url = SEARCH.goods
    } else if (options.type === 'selection') {
      url = SELEST.goods
    } else if (options.type === 'entity') {
      url = REAL.goods
    } else if (isPicked) {
      url = PICKED.goods
    } else if (options.type === 'userBehavior') {
      url = USER_BEHAVIOR.goods
    }

    const isListPage = ['selection', 'entity'].includes(options.type)

    const markApiEnd = customMarkPoint({ eventName: url })
    let schttpOptions = {}
    if (options.type === 'userBehavior') {
      schttpOptions = {
        method: 'POST',
        url,
        data: options.params,
        // data: {
        //   page: 1,
        //   size: 20,
        //   carrierSubType: '85',
        //   contentCarrierId: '2908100',
        //   entranceGoodsId: '26219580'
        // },
        useBffApi: true
      }
    } else {
      schttpOptions = {
        method: 'POST',
        url,
        params: options.params,
        isPreLoad: true,
        useBffApi: true,
        ...(isListPage ? { featureKey: 'click_refresh' } : {}),
        ...(isSearch ? { featureKey: 'search_cycle' } : {}),
        ...(isPicked && options.addUserBehaviorParams ? { featureKey: 'information_flow' } : {})
      }
    }
    if (!options.withoutAbort) {
      schttpOptions.signal = goodsAbortionInstance.signal
    }
    const res = await schttp(schttpOptions)

    markApiEnd()
  
    function handlePreloadImgs() {
      // 对 goods 中的前 4 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.info?.products?.slice(0, 4) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_images?.spu_images?.[0])
      // 取搜索店铺卡片图片
      // const searchStoreCardImgs = res?.searchBannerCard?.searchStoreCard?.products?.map(item => item.goods_img).filter(v => v) || []
      // 取搜索趋势卡片店铺图片
      // const trendCargBg = 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png'
      // const trendCardProductImg = res.searchBannerCard?.searchTrendCard?.mianImg // 商品图
      // const trendCardLineChat = res.searchBannerCard?.searchTrendCard?.growthLabel?.growthImage // 折线图
      // const trendCardImgs = [trendCardProductImg, trendCardLineChat]
      // if (trendCardImgs.length) {
      //   trendCardImgs.push(trendCargBg)
      // }

      Promise.all([
        handleGoodsImgLessen({ mainImages, prefetchGoods }),
        // 338 这个图片设计尺寸来自于列表双列布局下, 商品主图的设计稿尺寸, 参考 Item.vue 中的变量: columnDesignWidthMap
        preloadImg({ imgs: [...spuImages], designWidth: 338 }), 
        // 这里设计尺寸传 0, 是因为不用裁切, 只是做 webp 的替换
        // preloadImg({ imgs: [...searchStoreCardImgs, ...trendCardImgs], designWidth: 0 })
      ]).finally(() => {})
    }
    handlePreloadImgs()
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 搜索筛选
export let searchFilterAbortionInstance = null
export const searchFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (searchFilterAbortionInstance) {
      searchFilterAbortionInstance.abort()
    }
    searchFilterAbortionInstance = new SchttpAbortCon()

    const searchFilterPerformanceEnd = createPerformanceTrack({ url: SEARCH.filters })
    const endFilterPoint = customMarkPoint({ eventName: SEARCH.filters })
    const res = await schttp({
      url: SEARCH.filters,
      params: options.params,
      signal: searchFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    searchFilterPerformanceEnd?.()
    endFilterPoint?.()
    return res?.code === '0' ? res.info : {}
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 筛选项以及tsp标签

export let selectionAndEntityFilterAbortionInstance = null
export const selectionAndEntityFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (selectionAndEntityFilterAbortionInstance) {
      selectionAndEntityFilterAbortionInstance.abort()
    }

    selectionAndEntityFilterAbortionInstance = new SchttpAbortCon()

    let url = ''
    if (params._type === 'selection') {
      url = SELEST.filters
    } else if (params._type === 'entity') {
      url = REAL.filters
    }
    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: selectionAndEntityFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 图文导航
export let selectionAndEntityPicNavAbortionInstance = null
export const selectionAndEntityPicNavApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (params._requestType !== 'firstload') {
      return {}
    }
    
    if (selectionAndEntityPicNavAbortionInstance) {
      selectionAndEntityPicNavAbortionInstance.abort()
    }

    selectionAndEntityPicNavAbortionInstance = new SchttpAbortCon()

    let url = ''
    if (['selection', 'entity'].includes(params._type)) {
      url = COMMON.picNav
    }

    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: selectionAndEntityPicNavAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})


// 标签云
export let selectionAndEntityCloudTagAbortionInstance = null
export const selectionAndEntityCloudTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (selectionAndEntityCloudTagAbortionInstance) {
      selectionAndEntityCloudTagAbortionInstance.abort()
    }

    selectionAndEntityCloudTagAbortionInstance = new SchttpAbortCon()

    let url = ''
    if (params._type === 'selection') {
      url = SELEST.cloudTag
    } else if (params._type === 'entity') {
      url = REAL.cloudTag
    }

    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: selectionAndEntityCloudTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})


// 标签云中的date标签数据
export let categoryDayTagAbortionInstance = null
export const categoryDayTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (params._show_daily !== '1') {
      return {}
    }

    if (categoryDayTagAbortionInstance) {
      categoryDayTagAbortionInstance.abort()
    }

    categoryDayTagAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.categoryDay })
    const res = await schttp({
      url: COMMON.categoryDay,
      params: deleteUselessParams(params),
      signal: categoryDayTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 榜单插坑
export let rankListComponentAbortionInstance = null
export const rankListComponentApiCache = new ApiCache({
  cacheTime: 0, // 不缓存
  request: async (params) => {
    if (rankListComponentAbortionInstance) {
      rankListComponentAbortionInstance.abort()
    }

    rankListComponentAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.rankListComponent })
    const res = await schttp({
      url: COMMON.rankListComponent,
      params: deleteUselessParams(params),
      signal: rankListComponentAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    if (res?.info && params.delivery_place) {
      res.info.delivery_place = params.delivery_place
    }
    markApiEnd()
  
    return res
  },
  notCacheKeys: [],
})

export let cccCommonComponentAbortionInstance = null
export const cccCommonComponentApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (cccCommonComponentAbortionInstance) {
      cccCommonComponentAbortionInstance.abort()
    }
    cccCommonComponentAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.cccCommonComponent })
    const res = await schttp({
      url: COMMON.cccCommonComponent,
      params: params,
      signal: cccCommonComponentAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  notCacheKeys: [],
})

// 信息流落地页筛选项以及tsp标签

export let pickedFilterAbortionInstance = null
export const pickedFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (pickedFilterAbortionInstance) {
      pickedFilterAbortionInstance.abort()
    }

    pickedFilterAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_filter_goods' })

    const res = await schttp({
      url: PICKED.filters,
      params: options.params,
      signal: pickedFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 信息流落地页标签云
export let pickedCloudTagAbortionInstance = null
export const pickedCloudTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (pickedCloudTagAbortionInstance) {
      pickedCloudTagAbortionInstance.abort()
    }

    pickedCloudTagAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_filter_tagCloud' })

    const res = await schttp({
      url: PICKED.cloudTags,
      params: options.params,
      signal: pickedCloudTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
