// 职责：预请求，缓存数据当js内存中。
// 1. 相同参数的请求，只发一次。 如果同时发起多次相同参数的请求，只有第一次会发起请求，后续的请求会等待第一次请求的结果。
// 2. 如果返回的数据为空或者异常，返回数据，但是不缓存数据。
// 3. 缓存在内存的时间默认为 1 分钟，可以通过配置修改。

function generateKey(obj) {
  if (obj === null || typeof obj !== 'object') {
    return `${obj}`
  } else if (Array.isArray(obj)) {
    return `[${obj.map(generateKey).join(',')}]`
  } else {
    const keys = Object.keys(obj).sort()
    const pairs = keys.map(key => `${key}:${generateKey(obj[key])}`)
    return `{${pairs.join(',')}}`
  }
}

function generateCacheKeys(obj) {
  const keys = Object.keys(obj).sort()
  const pairs = []
  for (const key of keys) {
    const value = obj[key]
    const valueString = generateKey(value)
    const keyString = generateKey(key)
    pairs.push(`${keyString}:${valueString}`)
  }
  return pairs.join('&')
}

class DiffObj {
  constructor() {
    this.currentObj = null
  }

  handleDiffObj(newObj = {}) {
    if (!gbCommonInfo?.isDebug) return
    const diff = {}
    if (this.currentObj) {
      for (const key in newObj) {
        if (newObj[key] !== this.currentObj[key]) {
          diff[key] = newObj[key]
        }
      }
    }
    const res = Object.keys(diff).length > 0 ? diff : null
    this.currentObj = newObj
    // if (res) {
    //   console.log('当前请求参数和上一次请求参数变更[warn]:', res)
    // }
  }
}

class ApiCache extends DiffObj {
  constructor(config) {
    super()
    if (!config.request) {
      throw new Error('request is required')
    }

    this.cache = new Map()
    this.pendingRequests = new Map()
    this.config = Object.assign(
      {
        cacheTime: 1 * 60 * 1000, // 默认缓存时间为 1 分钟
        notCacheKeys: [],
        maxCacheNumber: 100 // 最大缓存数
      },
      config
    )

    this._clearCache()
  }

  request(params) {
    this.handleDiffObj(params)
    const cacheKey = this._getCacheKey(params)
    const cacheData = this._getCacheData(cacheKey)
    if (cacheData) {
      return Promise.resolve(cacheData)
    }

    if (this.pendingRequests.has(cacheKey)) {
      return this.pendingRequests.get(cacheKey)
    }

    const promise = new Promise((resolve, reject) => {
      this.config
        .request(params)
        .then(result => {
          if (result) {
            this.cache.set(cacheKey, {
              data: result,
              expireTime: Date.now() + this.config.cacheTime
            })
          }
          resolve(result)
        })
        .catch(reject)
        .finally(() => {
          this.pendingRequests.delete(cacheKey)
        })
    })
    this.pendingRequests.set(cacheKey, promise)
    return promise
  }

  getCacheData(params) {
    const cacheKey = this._getCacheKey(params)
    return this._getCacheData(cacheKey)
  }

  _getCacheData(cacheKey) {
    if (!this.cache.has(cacheKey)) {
      return null
    }
    const { data, expireTime } = this.cache.get(cacheKey)
    if (expireTime < Date.now()) {
      this.cache.delete(cacheKey)
      return null
    }
    return data
  }

  _getCacheKey(data) {
    return generateCacheKeys(
      Object.keys(data)
        .filter(key => !this.config.notCacheKeys.includes(key))
        .reduce((o, k) => {
          o[k] = data[k]
          return o
        }, {})
    )
  }

  _clearCache() {
    // 每隔 10 分钟清理一次缓存，减少内存占用
    setInterval(() => {
      if (this.cache.size === 0) return
      const now = Date.now()
      for (const [key, value] of this.cache.entries()) {
        if (value.expireTime <= now) {
          this.cache.delete(key)
        }
      }
      if (this.cache.size > this.config.maxCacheNumber) {
        this.cache.clear()
      }
    }, 1000 * 60 * 10)
  }

  clear() {
    this.cache.clear()
  }
}

export default ApiCache
