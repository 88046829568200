// 职责： 简化版asymc.auto，支持promise
function asyncAuto(tasks, callback) {
  const results = {} // 用来存储每个任务的结果
  const runningTasks = {} // 记录正在运行的任务
  const taskKeys = Object.keys(tasks)
  function hasNoPendingDependencies(taskName) {
    const task = tasks[taskName]
    if (Array.isArray(task)) {
      const dependencies = task.slice(0, -1)
      return dependencies.every(dep => results.hasOwnProperty(dep))
    }
    return true
  }
  function runTask(taskName) {
    runningTasks[taskName] = true
    const task = tasks[taskName]

    if (Array.isArray(task)) {
      const dependencies = task.slice(0, -1)
      // 检测当前dependencies里面都有在taskKeys中
      const notFound = dependencies.find(dep => !taskKeys.includes(dep))
      if (notFound) {
        callback(new Error(`async.auto task [${taskName}] has non-existent dependency in [${notFound}]`))
        return
      }
      const taskFn = task[task.length - 1]
      const resolvedDependencies = dependencies.reduce((acc, dep) => {
        acc[dep] = results[dep]
        return acc
      }, {})
      try {
        taskFn(resolvedDependencies, (err, result) => {
          if (err) {
            callback(err, results)
            return
          }
          results[taskName] = result
          delete runningTasks[taskName]
          checkPendingTasks()
        })
      } catch (err) {
        callback(err)
      }
    } else {
      if (typeof task !== 'function') {
        console.log('task is not defined', taskName, typeof task)
      }
      try {
        task((err, result) => {
          if (err) {
            callback(err, results)
            return
          }
          results[taskName] = result
          delete runningTasks[taskName]
          checkPendingTasks()
        })
      } catch (err) {
        callback(err)
      }
    }
  }
  function checkPendingTasks() {
    Object.keys(tasks).forEach(taskName => {
      if (
        !results.hasOwnProperty(taskName) &&
        !runningTasks.hasOwnProperty(taskName) &&
        hasNoPendingDependencies(taskName)
      ) {
        runTask(taskName)
      }
    })
    if (Object.keys(runningTasks).length === 0) {
      callback(null, results)
    }
  }
  checkPendingTasks()
}

function promiseAsyncAuto(tasks) {
  return new Promise((resolve, reject) => {
    asyncAuto(formatTaskConfig(tasks), (err, res) => err ? reject(err) : resolve(res))
  })
}

// 将任务配置对象转换为符合 asyncAuto 格式的对象
function formatTaskConfig(promiseObj = {}) {
  return Object.keys(promiseObj).reduce((acc, key) => {
    const promise = promiseObj[key]
    if (promise instanceof Promise) {
      acc[key] = cb => {
        promise
          .then(res => {
            cb(null, res)
          })
          .catch(err => {
            cb(err)
          })
      }
      return acc
    }
    if (Array.isArray(promise) || typeof promise === 'function') {
      acc[key] = promise
      return acc
    }
    throw new Error(`task ${key} is not a valid task, typeof ${typeof promise}`)
  }, {})
}

// 以下demo
// const fetchObject = {
//   init: async cb => {
//     cb(null, { init: 1})
//   },
//   test: (cb) => cb(null, 11),
//   p: Promise.resolve(1),
//   filter: [
//     'init',
//     (res, cb) => {
//       if (res.init) {
//         data.init = res.init
//       }
//       setTimeout(() => {
//         cb(null, data)
//       }, 500)
//     }
//   ],
// }
// asyncAuto(fetchObject, (err, res) => { })

export { asyncAuto, promiseAsyncAuto }
